import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "position" ]

  dragstart(event) {
    event.dataTransfer.setData("application/drag-key", event.target.getAttribute("data-lineitem-id"))
    event.dataTransfer.effectAllowed = "move"
  }

  dragover(event) {
    event.preventDefault()

    if (event.target.classList.contains('dropzone')) {
      event.target.classList.add('active')
      return true
    }

  }

  dragleave(event) {
    event.preventDefault()

    if (event.target.classList.contains('dropzone')) {
      event.target.classList.remove('active')
      return true
    }

  }

  dragenter(event) {
    event.preventDefault()
  }

  drop(event) {
    var data = event.dataTransfer.getData("application/drag-key")
    const dropTarget = event.target

    if (event.target.classList.contains('dropzone')) {

      event.target.classList.remove('active')

      const draggedItem = this.element.querySelector(`[data-lineitem-id='${data}']`);
      const positionComparison = dropTarget.compareDocumentPosition(draggedItem);

      if ( positionComparison & 4) { // dropTarget is before
        dropTarget.parentNode.insertAdjacentElement('beforebegin', draggedItem);
      } else if ( positionComparison & 2) { // dropTarget is after
        dropTarget.parentNode.insertAdjacentElement('afterend', draggedItem);
      }

      // Update params for new item positions
      this.positionTargets.forEach(function(item, index) {
        item.value = index
      })

    }

    event.preventDefault()
  }

  dragend(event) {
    // this is where we need to store the position...
  }

}
