import { Application } from "@hotwired/stimulus"
import { Datepicker } from "stimulus-datepicker"
import TextareaAutogrow from "stimulus-textarea-autogrow"

import { appsignal } from "../appsignal";
import { installErrorHandler } from "@appsignal/stimulus";

const application = Application.start()
installErrorHandler(appsignal, application);

application.register('datepicker', Datepicker);
application.register('textarea-autogrow', TextareaAutogrow)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
