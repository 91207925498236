import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = [ "menu", "sidebar", "content", "open", "close" ]

  connect() {
  }

  toggle(event) {

    console.log(1);

    this.menuTarget.classList.toggle("menu--open");
    //this.openTarget.classList.toggle("hidden");
    //this.closeTarget.classList.toggle("hidden");

    console.log( this.menuTarget )

    if(this.hasSidebarTarget) {
      //this.sidebarTarget.classList.toggle("hidden");
    }

    if(this.hasContentTarget) {
      //this.contentTarget.classList.toggle("hidden");
    }

  }

}
