import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

// Connects to data-controller="popup"
export default class extends Controller {
  static targets = ["trigger", "content"]

  triggerTargetConnected(trigger) {
    const content = $(trigger).children('[data-popup-target="content"]')[0];
    tippy(trigger, {
      content: content.innerHTML,
      allowHTML: true
    });
  }

  contentTargetConnected(content) {
    content.style.display = "none";
  }
}
