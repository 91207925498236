import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {

  static targets = ["tab", "menu"]

  connect() {
  }

  toggle(event) {
    const selectedTab = event.target.dataset.tab

    this.tabTargets.forEach(tab => {
      if (tab.getAttribute('id') === selectedTab) {
        tab.classList.remove("hidden")
      } else {
        tab.classList.add("hidden")
      }
    })

    this.menuTargets.forEach(menuItem => {
      if (menuItem.dataset.tab === selectedTab) {
        menuItem.classList.add("active");
      } else {
        menuItem.classList.remove("active");
      }
    });

  }

}
