import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selector" ]

  connect() {

    document.addEventListener('DOMContentLoaded', () => {
      const selectizeInput = this.element.querySelector('select');
      var value = '';
      var selectize = $(selectizeInput).selectize({
        placeholder: 'Select...',
        create: true,
        onInitialize: function(e) {
          value = this.getValue();
          var a,t=this;t.onKeyDown=(a=t.onKeyDown,function(e){if(1===t.settings.maxItems)switch(e.keyCode){case 27:case 78:case 40:case 80:case 38:case 13:case 37:case 39:case 9:case 8:case 46:break;default:return void t.deleteSelection({keyCode:8,preventDefault:function(){},stopPropagation:function(){}})}return a.apply(this,arguments)})
        },
        onDropdownOpen: function() {
          value = this.getValue();
          // this.clear();
        },
        onBlur: function() {
          if (!this.isFull()) {
            this.setValue(value);
          }
        }
      });
    });


    //$.fn.selectpicker.Constructor.DEFAULTS.style = 'btn-outline-light bg-white text-dark border border-form';

    // $(document).on('turbolinks:before-cache', function() {
    //   $(this.selectorTarget).selectpicker('destroy')
    // });

    // $(this.selectorTarget).selectpicker({
    //   dropdownAlignRight: 'auto',
    //   liveSearch: true,
    //   liveSearchPlaceholder: 'Search...',
    //   noneResultsText: 'Create {0}'
    // });

    // var parent = $(this.selectorTarget).closest('.bootstrap-select');
    // var parent_select = parent.find('select');
    // var new_option = this.selectorTarget.dataset.default;

    // if (new_option) {

    //   if (parent_select.val() !== new_option) {

    //     parent_select.append(
    //       new Option(new_option, new_option)
    //     );

    //     parent_select.find('option:last-child').attr('data-new', 'true');
    //     parent.addClass('new-option');

    //   }
    //   parent_select.selectpicker('val', new_option);
    //   parent_select.selectpicker('refresh');

    // }

  }

  create(event) {

    var parent = $(event.target).closest('.bootstrap-select');
    var parent_select = parent.find('select');

    if (event.target.classList.contains('no-results') ) {

      var new_option = parent.find('input[type=search]').val();
      parent_select.append(
        new Option(new_option, new_option)
      );

      parent_select.find('option:last-child').attr('data-new', 'true');
      parent_select.selectpicker('val', new_option);
      parent_select.selectpicker('refresh');
      parent.addClass('new-option');

    }

  }

  change(event) {

    var parent = $(event.target).closest('.bootstrap-select');
    var selected = parent.find('select option:selected')[0];

    // check if selected item is new...
    if( $(selected).data('new') ) {
      parent.addClass('new-option');
    } else {
      parent.removeClass('new-option');
    }

  }

}
