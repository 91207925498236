import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = [ "trigger", "content" ]

    connect() {
      this.hideContent()
    }

    toggle() {
      if (this.isVisible) {
        this.hideContent()
      } else {
        this.showContent()
      }
    }

    hideContent() {
      this.triggerTarget.classList.remove('rotate-180');
      this.contentTarget.style.display = "none"
    }

    showContent() {
      this.triggerTarget.classList.add('rotate-180');
      this.contentTarget.style.display = "block"
    }

    get isVisible() {
      return this.contentTarget.style.display === "block"
    }

}
