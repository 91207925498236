import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller {
  static targets = [
    "sku", "description", "price",
    "net", "tax", "total",
    "quantity", "taxcode", "destroy"
  ]

  static values = { 
    supplierProducts: Array,
    price: Number,
    net: Number,
    tax: Number,
    quantity: Number,
    total: Number
  }  

  static outlets = [ "net-tax-total" ]

  connect() {
    this.priceValue = this.priceTarget.value
  }

  supplierProductsValueChanged() {
    const skuOptionBuilder = (product) => { return {
        id: product.id,
        title: product.sku,
        secondary: product.name,
        tertiary: product.source
      }
    }
    const descriptionOptionBuilder = (product) => { return {
        id: product.id,
        title: product.name,
        secondary: product.sku,
        tertiary: product.source
      }
    }
    this.populateOptions(this.skuTarget, this.supplierProductsValue, skuOptionBuilder);
    this.populateOptions(this.descriptionTarget, this.supplierProductsValue, descriptionOptionBuilder);
  }

  populateOptions(target, products, optionBuilderCb) {
    // Destroy the current Selectize object, if it exists
    let selectizeControl = target.selectize;
    if (selectizeControl) { selectizeControl.destroy(); }

    // Initialize the Selectize control
    selectizeControl = $(target).selectize({
      plugins: ["restore_on_backspace"],
      searchField: 'title',
      valueField: 'title',
      labelField: 'title',
      closeAfterSelect: true,
      options: [],
      onChange: this.updatePrice.bind(this),
      create: true,
      render: {
        option: function (item, escape) {
          if ("secondary" in item){
            return `<div class="option grid grid-cols-2 gap-3">
                <div><p class="font-bold">${item.title}</p><p class="text-sm">(${item.secondary})</p></div>
                <div class="opacity-50"><p>${item.tertiary}</p></div>
              </div>`
          } else {
            return `<div class="option bg-indigo-50 grid grid-cols-2 gap-3">
                <div><p class="font-bold italic mb-2">${item.title}</p><p class="text-sm">(${target.dataset.secondary})</p></div>
              </div>`
          }
        }
      },
      onFocus: (e) => {
        $('select').not(target).toArray().forEach(select => select.selectize?.blur())
      }
    })[0].selectize;

    // Then create new options for each product
    products.forEach(product => {
      selectizeControl.addOption(optionBuilderCb(product));
    });
  }

  updatePrice(event) {
    const selectedProduct = this.supplierProductsValue.find(product => product.name === event || product.sku === event);

    if (selectedProduct) {
      if (!("unitPrice" in selectedProduct)) {
        const instance = tippy(this.priceTarget, {
          trigger: 'manual',
          content: "No price available in the Purchase Order currency",
          duration: [0, 1000],
          hideOnClick: true,
          interactive: true,
          showOnCreate: true,
          appendTo: document.body
        });
      }
      this.priceTarget.value = selectedProduct.unitPrice;
      this.calculate();
      this.descriptionTarget.value = selectedProduct.name;
      this.updateSku(selectedProduct.sku)
      this.updateDescription(selectedProduct.name)
    }
  }  

  updateSku(value) {
    $(this.skuTarget)[0].selectize.setValue(value);
  }

  updateDescription(value) {
    $(this.descriptionTarget)[0].selectize.setValue(value);
  }

  calculate() {
    this.netTaxTotalOutlet.updateSubtotals();
  }  
}
