// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
//  <h4>Tasks</h4>
//  <div data-controller="nested-form">
//    <template data-nested-form-target="template">
//      <%= form.fields_for :tasks, Task.new, child_index: 'NEW_RECORD' do |task| %>
//        <%= render "task_fields", form: task %>
//      <% end %>
//    </template>
//
//    <%= form.fields_for :tasks do |task| %>
//      <%= render "task_fields", form: task %>
//    <% end %>
//
//    <div class="mb-3" data-nested-formtarget="links">
//      <%= link_to "Add Task", "#", class: "btn btn-outline-primary", data: { action: "click->nested-form#add_association" } %>
//    </div>
//  </div>
//
//  # _task_fields.html.erb
//  <%= content_tag :div, class: "nested-fields", data: { new_record: form.object.new_record? } do %>
//    <div class="form-group">
//      <%= form.label :description %>
//      <%= form.text_field :description, class: 'form-control' %>
//      <small><%= link_to "Remove", "#", data: { action: "click->nested-form#remove_association" } %></small>
//    </div>
//
//    <%= form.hidden_field :_destroy %>
//  <% end %>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields";
  }

  add(event) {
    event.preventDefault()

    var lastPosition = parseInt($("[data-target='drag-item.position']:last").val());
    var nextPosition = lastPosition + 1;
    var content = this.templateTarget.innerHTML
      .replace(/NEW_RECORD/g, new Date().getTime())
      .replace(/POSITION/g, nextPosition)

    this.linksTarget.insertAdjacentHTML('beforebegin', content)

    var select = $(this.linksTarget.previousElementSibling).find('select');
/*
    var product_select = $(this.linksTarget.previousElementSibling).find('select[data-field="product_id"]');

    if (product_select) {
      new_products = generateProducts()
      product_select.empty().append( objectsToOptions(new_products) );
      product_select.find('option:first').hide();
    }
*/

    // select.selectpicker({
    //   dropdownAlignRight: 'auto',
    //   liveSearch: true,
    //   liveSearchPlaceholder: 'Search...',
    //   noneResultsText: 'Create {0}'
    // });
  }

  remove(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }
}
