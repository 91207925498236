import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller {
  static targets = ["productSelect", "price"]

  static values = {
    companyProducts: Array
  }

  static outlets = [ "net-tax-total" ]

  companyProductsValueChanged() {
    this.populateProducts();
  }

  populateProducts() {
    // Destroy the current Selectize object, if it exists
    let selectizeControl = this.productSelectTarget.selectize;
    if (selectizeControl) {
      selectizeControl.destroy();
    }

    // Initialize the Selectize control
    selectizeControl = $(this.productSelectTarget).selectize({
      searchField: 'title',
      valueField: 'id',
      labelField: 'title',
      options: [],
      onChange: this.updatePrice.bind(this),
      create: false
    })[0].selectize;

    this.companyProductsValue.forEach(product => {
      const option = {
        id: product.id,
        title: product.name
      };
      selectizeControl.addOption(option);
    });
  }

  updatePrice(){
    const productId = +this.productSelectTarget.selectize.getValue();
    const selectedProduct = this.companyProductsValue.find(product => product.id === productId);
    if (selectedProduct){
      this.priceTarget.value = selectedProduct.unitPrice;
    } else {
      this.priceTarget.value = undefined;
    }

    this.netTaxTotalOutlet.updateSubtotals();
  }
}