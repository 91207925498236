import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["contact", "company", "project", "currency"]

  static values = {
    contactOptions: Object,
    companyOptions: Object,
    projectOptions: Object,
    companyProducts: Array,
    accountId: String
  }

  connect() {
    this.initContactSelect()
    this.initCompanySelect()
    this.initProjectSelect()

    const company = this.companyTarget.selectize.getValue()
    if (company !== ""){
      this.populateForCompany(company)
    }
  }

  static outlets = [ "quote-line-item" ]

  quoteLineItemOutletConnected(outlet, element) {
    outlet.companyProductsValue = this.companyProductsValue;
  }

  quoteLineItemOutletDisconnected(outlet, element) {}

  currencyTargetConnected(){ this.currencyChanged() }

  currencyChanged(){
    if (this.currencyTarget.value === ""){
      $('a[data-action="click->nested-form#add"]').addClass('disabled');
      $('#currency-information').removeClass('hidden');
    } else {
      $('a[data-action="click->nested-form#add"]').removeClass('disabled');
      $('#currency-information').addClass('hidden');
    }
  }

  initContactSelect() {
    let selectizeControl = this.contactTarget.selectize;
    if (selectizeControl) { selectizeControl.destroy(); }

    selectizeControl = $(this.contactTarget).selectize({
      onChange: this.selectContactCompany.bind(this),
      create: true
    })[0].selectize;

    this.contactOptionsValue = selectizeControl.options;
  }

  initCompanySelect() {
    let selectizeControl = this.companyTarget.selectize;
    if (selectizeControl) { selectizeControl.destroy(); }

    selectizeControl = $(this.companyTarget).selectize({
      onChange: this.populateForCompany.bind(this),
      create: true
    })[0].selectize;

    this.companyOptionsValue = selectizeControl.options;
  }

  initProjectSelect() {
    let selectizeControl = this.projectTarget.selectize;
    if (selectizeControl) { selectizeControl.destroy(); }

    selectizeControl = $(this.projectTarget).selectize({
      create: true
    })[0].selectize;

    this.projectOptionsValue = selectizeControl.options;
    this.filterCompanyProjects(this.companyTarget.value);
  }

  fetchCompanyProducts(cb) {
    const selectedCompany = this.companyOptionsValue[this.companyTarget.value];
    fetch(`/a/${this.accountIdValue}/companies/${selectedCompany.id}/products.json`)
      .then(response => response.json())
      .then(cb.bind(this));
  }

  companyProductsValueChanged() {
    this.quoteLineItemOutlets.forEach((outlet) => {
      outlet.companyProductsValue = this.companyProductsValue;
    });
  }

  populateForCompany(company){
    this.useDefaultCurrency(company);
    this.filterCompanyProjects(company);
    this.fetchCompanyProducts(function(data){
      const products = data.filter((product) => {
        product.unitPrice = product.sale_price_ids[this.currencyTarget.value];
        return (!(product.unitPrice === undefined));
      })
      this.companyProductsValue = products;
    })
  }

  useDefaultCurrency(company) {
    const selectedCompany = this.companyOptionsValue[company];
    if (selectedCompany.currency_id !== undefined) {
      $(this.currencyTarget).children(`option[value=${selectedCompany.currency_id}]`).prop("selected", true);
      this.currencyChanged()
    }
  }

  selectContactCompany(contact) {
    const selectedContact = this.contactOptionsValue[contact] || {};

    if (contact === "") {
      // No company id for new contact, unset company value
      $(this.companyTarget)[0].selectize.clear();
    } else {
      const companyPairs = Object.entries(this.companyOptionsValue);
      const foundEntry   = companyPairs.find(([key, value]) => value.id === selectedContact.company_id);
      const companyName  = foundEntry ? foundEntry[0] : null;
      if (companyName) {
        $(this.companyTarget)[0].selectize.setValue(companyName);
      }
    }
  }

  filterCompanyProjects(company) {
    const selectedCompany = this.companyOptionsValue[company];
    const projectPairs = Object.entries(this.projectOptionsValue);

    if (selectedCompany === undefined) {
      // No company chosen or new company added - Filter to projects without company
      projectPairs.forEach(([key, v]) => {
        if (v.company_id !== undefined) {
          $(this.projectTarget)[0].selectize.removeOption(key);
        } else {
          $(this.projectTarget)[0].selectize.addOption(v);
        }
      });
    } else {
      // Existing company - Filter to projects matching company or without company
      let acceptedOption = undefined;

      projectPairs.forEach(([key, v]) => {
        if (v.company_id !== undefined && v.company_id !== selectedCompany.id) {
          $(this.projectTarget)[0].selectize.removeOption(key);
        } else {
          $(this.projectTarget)[0].selectize.addOption(v);
          acceptedOption = key;
        }
      });

      if ($(this.projectTarget)[0].selectize.options.length === 1) {
        $(this.projectTarget)[0].selectize.setValue(acceptedOption)
      }
    }
  }
}