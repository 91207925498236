import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller {
  static targets = [
    "supplier", "accountNumber", "lineItemDescription", "lineItemSku", "currency"
  ]

  static values = {
    accountId: String,
    supplierProducts: Array
  }

  static outlets = [ "line-item" ]

  lineItemOutletConnected(outlet, element) {
    outlet.supplierProductsValue = this.supplierProductsValue;
    this.itemAmountChanged();
  }

  lineItemOutletDisconnected(outlet, element) {
  }

  supplierProductsValueChanged() {
    this.lineItemOutlets.forEach((outlet) => {
      outlet.supplierProductsValue = this.supplierProductsValue;
    });
  }

  connect() {
    this.fetchSupplier();
    this.currencyTip = tippy(this.currencyTarget.parentElement, {
      trigger: 'mouseenter',
      content: "You must remove line items before changing currency",
      duration: [0, 1000],
      hideOnClick: true,
      interactive: true,
      showOnCreate: false
    });
    this.currencyTip.disable();
  }

  lockCurrency(isLocked){
    this.currencyTarget.toggleAttribute("disabled", isLocked);
    this.currencyTarget.classList.toggle("bg-gray-100", isLocked);
    if (this.currencyTip === undefined) { return; }
    if (isLocked){
      this.currencyTip.enable()
    } else {
      this.currencyTip.disable();
    }
  }

  itemAmountChanged(){
    const activeItems = this.lineItemOutlets.filter((item) => { return item.destroyTarget.value !== "1" });
    this.lockCurrency(activeItems.length > 0)
  }

  fetchSupplier() {
    if(this.supplierTarget.value !== "") {
      fetch(`/a/${this.accountIdValue}/companies/${this.supplierTarget.value}.json`)
        .then(response => response.json())
        .then(data => {
          this.populateAccountNumber(data);
          this.populateCurrency(data);
          this.fetchSupplierItemsAndProducts();
        });
    }
  }

  fetchSupplierItemsAndProducts(){
    const fetch1 = this.fetchSupplierProducts();
    const fetch2 = this.fetchSupplierItems();

    Promise.all([fetch1, fetch2])
      .then(results => {
        const [data1, data2] = results;
        const products = data1.map((p) => { p["source"] = "Product"; return p })
        const line_items = data2.map((p) => { p["source"] = "Purchase"; return p })
        const combinedData = [...products, ...line_items];
        this.populateLineItemDescriptions(combinedData);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  fetchSupplierProducts() {
    return fetch(`/a/${this.accountIdValue}/companies/${this.supplierTarget.value}/products.json`)
      .then(response => response.json())
  }

  fetchSupplierItems(products) {
    return fetch(`/a/${this.accountIdValue}/companies/${this.supplierTarget.value}/supplied_items.json`)
      .then(response => response.json())
  }

  populateLineItemDescriptions(data) {
    let productOptions = data.map(product => {
      let productInfo = product.purchase_costs?.[this.currencyTarget.value]?.find(pc => pc.company_id === Number(this.supplierTarget.value)) || {};
      const sku  = productInfo?.sku ? productInfo.sku : product.sku;
      const name = product.description ? product.description : product.name;
      return {id: product.id, source: product.source, name: name, sku: sku, unitPrice: productInfo?.amount, currency: product.currency};
    });

    this.lineItemDescriptionTargets.forEach((box) => {
      box.value = productOptions[0].name; // Set the value for each 'box' element
    });
    this.supplierProductsValue = productOptions;
  }

  populateAccountNumber(data) {
    this.accountNumberTarget.value = data.account_number ?? '';
  }

  populateCurrency(data) {
    // Update currency if supplier has a default
    if(data.currency_id) {
      this.currencyTarget.value = data.currency_id
    }
  }

  populateOptions(target, products, defaultText, optionTextCallback) {
    // Destroy the current Selectize object, if it exists
    let selectizeControl = target.selectize;
    if (selectizeControl) {
        selectizeControl.destroy();
    }

    // Initialize the Selectize control
    selectizeControl = $(target).selectize({
      valueField: 'title',
      labelField: 'title',
      options: [],
      onChange: () => { alert('changed') },
      create: true
    })[0].selectize;

    // Then create new options for each product
    products.forEach(product => {
        selectizeControl.addOption({
          id: product.id,
          title: product.full_name
        });
    });
  }
}
